<template>
  <v-container class="pa-0" fluid>
    <v-container class="pb-0">
      <!-- header -->
      <header-sheet />
    </v-container>

    <section class="py-12">
      <v-container class="mb-6 mb-xl-12">
        <h1 class="text-h4 text-xl-h3 font-weight-black font-italic">
          ARENA E-SPORTS
        </h1>
      </v-container>
    </section>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>